/* You can add global styles to this file, and also import other style files */

.nav,
.pagination,
.carousel,
.panel-title a {
    cursor: pointer;
}
.share-btn {
    display: none;
}
.share-btn .dropdown-menu{
    width: fit-content;
}
.list-table tr:hover .share-btn {
    display: block;
}
.sync-col-fileimage {
    height: 40px;
    width: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position: center;
}

.ng-valid[required],
.ng-valid.required {
    border-left: 5px solid #42a948; /* green */
}

.ng-dirty .ng-invalid:not(form) {
    border-left: 5px solid #a94442; /* red */
}

.preview-text-content {
    white-space: pre-wrap;
}

.preview-wrap-fullscreen .preview-loading-wrap {
    color: white;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.preview-wrap-img-fullscreen {
    padding: 0px !important;
    max-height: 100vh !important;
}
.preview-wrap-fullscreen {
    z-index: 10000 !important;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    margin: 0px !important;
    top: 0 !important;
    left: 0 !important;
    background-color: #191919 !important;
}

.preview-image-nav-fullscreen {
    right: 0 !important;
    width: 280px !important;
}
.iframe-container {
    /* overflow: hidden; */
    /* padding-top: 56.25%; */
    padding-top: 1.5em;
}

@media (max-width: 767px) {
    .iframe-container {
      padding-top: 3em;
    }
  }

iframe.billing-profile {
    /* left: 0;
     position: absolute;
     top: 0; */
    min-height: 910px;
    display: block;
    border: none;
    width: 100%;
}

/* .file-list-menu-wrap {
    width: 100px;
    height: 100px;
    z-index: 1010; /* higher than the share button */
/* } */

/* angular */

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak,
.ng-hide {
    display: none !important;
}

ng\:form {
    display: block;
}
.pcc-hyperlink-menu {
    z-index: 2000; /** must be higher than .modal-dialog **/
}
.pccv-wrapper {
    position: absolute;
    top: 50px;
    bottom: 50px;
    left: 15px;
    right: 15px;
}
.pccv {
    width: 100%;
    height: 100%;
}
/** PDFJS **/
.pdfViewer .page {
    background-color: white;
    border: none;
    margin: 10px auto;
}

/** Bootstrap 4 */

/** Datepicker styles **/
.ngb-dp-day,
.ngb-dp-week-number,
.ngb-dp-weekday {
    width: 3rem !important;
    height: 3rem !important;
    line-height: 3rem !important;
    vertical-align: middle !important;
}
[ngbDatepickerDayView] {
    width: 3rem !important;
    height: 3rem !important;
    line-height: 3rem !important;
}
.ngb-dp-header {
    background-color: #efefef;
}
.bg-primary {
    background-color: #00adef !important;
}

/** Time picker styles **/
.ngb-tp-input-container {
    width: 5.5em !important;
}

.infinity-space {
    font-weight: 900;
    font-family: monospace;
    color: #0d3b66;
    font-size: 60px;
    line-height: 40px;
    margin-bottom: 10px;
}

.invite-content {
    margin-bottom: 30px;
}

.invite-content > p {
    margin-bottom: 20px;
}

.invite-content-box {
    text-align: center;
}

.content-override {
    text-align: left;
    padding: 20px;
    font-size: 14px;
    line-height: 28px;
}

.content-override ol {
    padding-right: 40px;
}

.usrs span {
    display: inline-block;
    margin-right: 5px;
}

/** Account getspace page **/

.upgrade-selections {
    text-align:center;
    padding-top:24px;
    padding-bottom:16px;
}

.upgrade-selections>.btn-primary {
    background-color: #ffffff;
    border-color: #00adef;
    color:#00adef;
    font-size: 17px;
}
    
.upgrade-selections>.btn-selected {
    background-color: #00adef;
    border-color: #00adef;
    color:#ffffff;
    font-size: 17px;
}

.overlay-closer {
    position: absolute;
    right: 0;
    top: 50%;
    padding: 10px 12px;
    transform: translate(0, -50%);
    cursor: pointer;
}

.right-menu-icon {
    position: absolute;
    bottom: 0;
    right: 0;
}

.right-menu-icon i {
    font-size: 40px;
}

.popover-content {
    width: 200px;
}

.file-upload {
    display: table;
    height: calc(100vh - 70vh);
    width: 100%;
    transition: ease 1s;
    margin: 24px 0 0;
}

.file-upload-inner {
    text-align: center;
    vertical-align: middle;
    border-style: dashed;
    border-color: #707070;
    display: table-cell;
    vertical-align: middle;
}

.uploaded-file-container {
    display: inline-block;
    width: 25%;
    max-height: 50%;
    background-color: #fff;
    position: relative;
    overflow: hidden;
}

.uploaded-file-container.alert-danger {
    background-color: #f2dede;
}

.uploaded-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 10px;
    word-break: break-word;
}

.uploaded-file-container .close {
    position: absolute;
    right: 5px;
}

.file-upload-msg {
    font-size: 22px;
    font-weight: bold;
}

.primary-outline {
    background-color: transparent;
    color: inherit;
    transition: all .5s;
}

.btn-primary.primary-outline {
    color: #00adef;
}


.btn-primary.primary-outline:hover {
    color: #fff;
}

.sync-btn.btn-primary {
    background-color: #00adef;
    border-color: #00adef;
}

.sync-btn.btn-primary:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74;
}

/* File List Menu dropdown */
.file-list-menu-dropdown {
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    z-index: 1;
}

/* File List Menu dropdown end */

.cdk-overlay-panel .mat-elevation-z4 {
    border: 1px solid rgba(0, 0, 0, .15);
    box-shadow: none;
}

.plan-details-desabled {
    opacity: 0.3;
    pointer-events: none;
}

.sticky-message {
    font-size: 17px;
    padding: 0 !important;
    margin-bottom: 20px;
}

.sticky-message-title {
    background-color: #00ADEF;
    padding: 14px 20px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    font-size: 20px;
}

.sticky-message-body {
    padding: 14px 20px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.sticky-message-body a {
    cursor: pointer;
}

.sticky-message-body span {
    color: #000 !important;
}
.multi-user-table .name-column,
.multi-user-table .name-column {
    width: 40%;
}

.multi-user-table td:last-child,
.multi-user-table th:last-child {
    min-width: 20px;
    max-width: 20px;
}

@media (max-width: 991px) {
    .multi-user-table .name-column,
    .multi-user-table .name-column {
        width: 60%;
    }

    .multi-user-table .tdmore {
        white-space: normal;
        word-break: break-word;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .multi-user-table .name-column,
    .multi-user-table .name-column {
        width: 30%;
    }
    .multi-user-table .tdmore {
        white-space: normal;
        word-break: break-word;
    }
  }
  
/* ngbTooltip */

.tooltip{
    opacity:1!important;
  }

.hamburger-menu {
    position: absolute;
    right: 0;
    top: 0;
}

.hamburger-menu .navrightdrop {
    position: relative;
    width: 170px;
}

.hamburger-menu .navrightdrop-ul {
    width: 170px;
}

.hamburger-menu-item ul {
    padding: 0;
    margin: 0;
    border: none;
}

.hamburger-menu-item a.syncblue {
    color: black !important;
}

.hamburger-menu-item i {
    display: none;
}

@media (max-width: 767px) {
    .xs-fixed-comment {
        position: fixed;
        width: 100%;
        bottom: 25px;
        right: 0;
        height: 50px;
        overflow: hidden;
    }

    .xs-fixed-comment.expanded {
        height: 300px;
        background-color: #fff;
        overflow: auto;
    }
}

.role-filter {
    margin-top: 15px;
    width:230px;
    font-size: 14px;
    display: inline-block;
}

.role-filter label,
.role-filter select,
.role-filter option {
    font-size: 14px;
    color: #000 !important;
}

.fa-square.disabled {
    background-color: #e7e7e7;
    cursor: not-allowed;
}

ngb-popover-window {
    position: absolute;
    width: auto !important;
    display: block !important;
    background-color: #fafafa !important;
    border: 1px solid #fafafa !important;
    max-width: none !important;
}

.invite-roles-dropdown option:disabled  {
    color: #b2b2b2 !important;
}

textarea.form-control {
    max-width: 100%;
}
@media screen and (max-width: 991.97px) {
    .visible-landscape {
        display: block !important;
    }
    .hidden-landscape {
        display: none !important;
    }
}

.drop-box-height {
    height: calc(100vh - 150px);
}

.bottom-margin-40 {
    margin-bottom: 40px;
}

/* below is global css for password show hide icon and placement
input field shape and size is not same everywhere.
In that case identity can be used to overrite this to achieve proper UI */

.passcover {
    position: relative;
}

.passcover input::-ms-reveal,
.passcover input::-ms-clear {
    display: none;
}

.passcover [syncShowHidePassword] {
    padding: 10px 50px 10px 16px;
}

.passcover span.showhideIcon {
    position: absolute;
    bottom: 10px;
    right: 15px;
    cursor: pointer;
}

.passcover.linkpass span.showhideIcon,
.passcover.usernamechangepass span.showhideIcon,
.passcover.twofactorpass span.showhideIcon,
.passcover.confirmpass span.showhideIcon {
    bottom: 5px;
    right: 30px;
}

.passcover.userinvitepass span.showhideIcon {
    bottom: 12px;
    right: 17px;
}

.passcover.linkconsumepass span.showhideIcon,
.passcover.subuserpasschange span.showhideIcon {
    bottom: 5px;
}

.passcover.luseraddpass span.showhideIcon {
    bottom: 5px;
    right: 17px;
}

.passcover.shareprovisionpass span.showhideIcon {
    bottom: 12px;
}

.passcover span.showhideIcon .fa {
    color: #A2A2A2;
}

.passcover span.showhideIcon .fa:hover {
    color: #707070;
}

.passcover span.showhideIcon .fa:focus {
    color: #444444;
}


.try-new-ui{
    display: flex !important;
    gap:10px;
    align-items: center;
}

.try-new-ui .beta{
    color: #007BAA;
    font-feature-settings: 'liga' off, 'clig' off;
    width: 52px;
    height: 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.5px;
    background-color:rgba(131, 207, 255, 0.24);
}