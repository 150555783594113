body {
    /*min-height:75rem;*/
    font-family: "Lato", sans-serif;
    font-weight: 400;
    color: #444444;
}

.container-fluid-wrap {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 70px;
    padding-bottom: 76px;
}

.container-fluid-wrap-link {
    padding-left: 32px;
    /* padding-right: 32px; */
    /* padding-top: 70px; */
    padding-bottom: 76px;
    margin-top: -20px;
}

@media (max-width: 992px) {
    .container-fluid-wrap,
    .container-fluid-wrap-link {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.container-fluid-wrap-preview {
    height: 100vh;
    padding-bottom: 0px;
    /*background-color:#eaeaea;*/
}

@media (max-width: 992px) {
    .container-fluid-wrap-preview {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.syncblue {
    color: #00adef !important;
}

.syncdarkblue {
    color: #0d3b66 !important;
}

.syncgreen {
    color: #00ff7f !important;
}

.syncblacklink {
    color: #000000;
}
.syncblacklink:hover {
    color: #00adef;
    text-decoration: none;
}

/* BUTTONS */

.btn-lg {
}
.input-lg {
}

/* PROGRESS BAR DEFAULT */

.progress {
    height: 4px;
    margin-bottom: 0;
}

.progress-bar {
    background-color: #0d3b66;
}

/* PAGINATION */

.pagination > li > a {
    color: #00adef;
}

.table-pagination .pagination {
    padding-top: 0px;
    margin-top: 0px;
}

.sync-pagination {
    font-family: Lato, FontAwesome;
}

.pagination > li.active > a {
    background: #00adef;
    color: #fff;
    border-color: #00adef;
    z-index: 0;
}

.pagination > li.active > a:hover {
    background: #00adef;
    border-color: #00adef;
}

/* MODALS */

.modal-backdrop {
    z-index: 5000 !important;
}

.modal {
    z-index: 5001 !important;
}

.modal-footer > .btn {
    font-size: 17px;
}

.modal-footer > .btn-primary {
    background-color: #00adef !important;
    border-color: #00adef;
}

.modal-footer > .btn-primary:hover {
    background-color: #0d3b66 !important;
    border-color: #0d3b66;
}

.modal-title {
    font-size: 20px;
    padding-left: 16px;
    padding-right: 16px;
}

.modal-body {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 16px;
    background-color: #f5f5f5;
    font-size: 16px;
}

.modal-footer {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 28px;
    padding-top: 28px;
    border-top-width: 0px;
    background-color: #f5f5f5;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.footer-not-used {
    padding-bottom: 18px;
    padding-top: 0px;
}

.modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
    -o-box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
}

.modal-dialog {
    z-index: 2000;
}

@media (min-width: 768px) {
    .modal-dialog {
        width: 720px;
    }
}

.modal-body > .row > .col-sm-4 {
    padding-top: 6px;
}

@media (max-width: 768px) {
    .modal-body > .row > .col-sm-4 {
        padding-bottom: 8px;
        padding-top: 0px;
    }
}

.modal-body > hr {
    border-top-color: #e2e2e2;
}

.modal-body > .row > .col-sm-8 > .form-control,
.modal-body > .row > .col-sm-12 > .form-control {
    font-size: 16px !important;
}

.row-vertical-center {
    display: flex;
    align-items: center;
}

.radio-top {
    padding-top: 0px;
    margin-top: 0px;
}

.radio-top .disable-label{
    color:grey;
}

.disable-label-all .radio label{
    color:grey;
}

.radio-bottom {
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.modalsharepermissions {
    font-size: 15px;
    text-align: left;
}

.modal-dialog-overflow {
    overflow-y: initial !important;
}

.modal-body-overflow {
    max-height: calc(100vh - 220px);
    min-height: calc(100vh - 220px);
    overflow-y: auto;
    padding-left: 16px;
    padding-right: 16px;
}

.modal-transfer-empty {
    display: table;
    height: 140px;
    width: 100%;
    transition: ease 1s;
}
	
.modal-transfer-empty-height {    
    margin-bottom: 15px;
}

.modal-transfer-empty-inner {
    text-align: center;
    vertical-align: middle;
    border-style: dashed;
    border-color: #707070;
    display: table-cell;
    vertical-align: middle;
}

/* MODEL VIEW SPECIFIC */

.link-settings-buttons {
    padding-top: 16px;
    text-align: left;
    display: flow-root;
}

.link-settings-buttons > .btn {
    font-size: 17px;
}
.link-settings-buttons > .btn-primary {
    background-color: #00adef !important;
    border-color: #00adef;
}

.link-settings-buttons > .btn-primary:hover {
    background-color: #0d3b66 !important;
    border-color: #0d3b66;
}

.shareuserbadges {
    padding-top: 16px;
}

.shareuserbadges > .badge {
    font-size: 15px;
    margin-right: 5px;
    border-radius: 8px;
}

/* NAVBAR */
.nav a {
    font-size: 18px !important;
}
.navbar-fixed-top {
    min-height: 70px;
    padding-right: 20px;
    z-index: 1050;
}

.navbar-fixed-top-link {
    min-height: 70px;
    padding-right: 20px;
    z-index: 1050;
    position: relative;
    top: 0px;
    border-width: 0 0 1px;
    border-radius: 0;
}

.navbar-fixed-top-preview {
    min-height: 70px;
    padding-right: 20px;
    z-index: 1100;
    position: relative;
    top: 0px;
    width: 100%;
    border-width: 0 0 1px;
    border-radius: 0;
}

.navbar-brand {
    height: 70px;
}

.navbar-nav > li > a {
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 70px;
}

.navbar-default {
    background-color: #00adef;
    border-color: #00adef;
}

.navbar-default .navbar-brand {
    color: #ffffff;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
    color: #0d3b66;
}

.navbar-default .navbar-text {
    color: #ffffff;
}

.navbar-default .navbar-nav > li > a {
    color: #ffffff;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: #0d3b66;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: #0d3b66;
    background-color: #00adef;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    color: #0d3b66;
    background-color: #00adef;
}

.navbar-default .navbar-toggle {
    border-color: #00adef;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #00adef;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #ffffff;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border-color: #ffffff;
}

.navbar-default .navbar-link {
    color: #ffffff;
}

.navbar-default .navbar-link:hover {
    color: #0d3b66;
}

/* NAVBAR COLLAPSE SAFETY CODE */

.navbar-collapse.collapse {
    display: block !important;
}

.navbar-nav > li,
.navbar-nav {
    float: left !important;
}

.navbar-nav.navbar-right:last-child {
    margin-right: -15px !important;
}

.navbar-right {
    float: right !important;
}

@media (max-width: 768px) {
    .navbar-header {
        float: left;
    }

    .navbar {
        min-width: 293px;
    }

    .navbar-collapse {
        width: auto;
        border-top: 0;
        box-shadow: none;
    }
    .navbar-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important;
    }
    .navbar-collapse.in {
        overflow-y: visible;
    }
    .navbar-fixed-top .navbar-collapse,
    .navbar-static-top .navbar-collapse,
    .navbar-fixed-bottom .navbar-collapse {
        padding-right: 0;
        padding-left: 0;
    }
    .container > .navbar-header,
    .container-fluid > .navbar-header,
    .container > .navbar-collapse,
    .container-fluid > .navbar-collapse {
        margin-right: 0;
        margin-left: 0;
    }
    .navbar-static-top {
        border-radius: 0;
    }
    .navbar-fixed-top,
    .navbar-fixed-bottom {
        border-radius: 0;
    }
    .navbar-toggle {
        display: none;
    }
    .navbar-nav > li {
        float: left;
    }

    .navbar-nav.navbar-right:last-child {
        margin-right: -15px;
    }
    .navbar-left {
        float: left !important;
    }
    .navbar-right {
        float: right !important;
    }
    .navbar-form .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
    }
    .navbar-form .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
    .navbar-form .control-label {
        margin-bottom: 0;
        vertical-align: middle;
    }
    .navbar-form .radio,
    .navbar-form .checkbox {
        display: inline-block;
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle;
    }
    .navbar-form .radio input[type="radio"],
    .navbar-form .checkbox input[type="checkbox"] {
        float: none;
        margin-left: 0;
    }
    .navbar-form .has-feedback .form-control-feedback {
        top: 0;
    }
    .navbar-form {
        width: auto;
        padding-top: 0;
        padding-bottom: 0;
        margin-right: 0;
        margin-left: 0;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .navbar-form.navbar-right:last-child {
        margin-right: -15px;
    }
    .navbar-text {
        float: left;
        margin-right: 15px;
        margin-left: 15px;
    }
    .navbar-text.navbar-right:last-child {
        margin-right: 0;
    }
    .navbar-nav .open .dropdown-menu {
        position: absolute;
        -webkit-box-shadow: black;
        box-shadow: aliceblue;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background-color: #fff;
        width: auto;
    }
    .navbar-nav {
        margin: 0;
    }
    .navbar-nav .open .yep-notify {
        right: 0;
        left: auto;
        width: 299px;
    }
}

.navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
}

.navbar-collapse.in {
    overflow-y: visible;
}

.navbar-fixed-top .navbar-collapse,
.navbar-static-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
}

.container > .navbar-header,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
}

.navbar-static-top {
    border-radius: 0;
}

.navbar-fixed-top,
.navbar-fixed-bottom {
    border-radius: 0;
}
.nav-tabs > li > a {
    font-size: 14px !important;
    color: #00adef;
}

.nav-tabs {
    border-bottom-width: 2px;
}

.nav-tabs > .active > a {
    border-bottom: none;
    position: relative;
    top: 1px;
}

.navbar-toggle {
    display: none;
}

.navbar-nav > li {
    float: left;
}

.navbar-nav.navbar-right:last-child {
    margin-right: -15px;
}

.navbar-left {
    float: left !important;
}

.navbar-right {
    float: right !important;
}

.navbar-form .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}

.navbar-form .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}

.navbar-form .control-label {
    margin-bottom: 0;
    vertical-align: middle;
}

.navbar-form .radio,
.navbar-form .checkbox {
    display: inline-block;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
}

.navbar-form .radio input[type="radio"],
.navbar-form .checkbox input[type="checkbox"] {
    float: none;
    margin-left: 0;
}

.navbar-form .has-feedback .form-control-feedback {
    top: 0;
}

.navbar-form {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.navbar-form.navbar-right:last-child {
    margin-right: -15px;
}

.navbar-text {
    float: left;
    margin-right: 15px;
    margin-left: 15px;
}

.navbar-text.navbar-right:last-child {
    margin-right: 0;
}

.navbar-nav .open .dropdown-menu {
    position: absolute;
    -webkit-box-shadow: black;
    box-shadow: aliceblue;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-color: #fff;
    width: auto;
}

.navbar-nav {
    margin: 0;
}

.navbar-nav .open .yep-notify {
    right: 0;
    left: auto;
    width: 299px;
}

/* NAVBAR TABS */

.nav-tabs > li > a {
    font-size: 14px !important;
    color: #00adef;
}

.nav-tabs {
    border-bottom-width: 2px;
}

.nav-tabs > .active > a {
    border-bottom: none;
    position: relative;
    top: 1px;
}

/* NAVBAR DROPDOWN MENUS */

.dropdown-regular > li > a {
    font-size: 17px !important;
}

.dropdown-small > li > a,
.dropdown-small > li {
    font-size: 15px !important;
}

.dropdown-menu {
    padding-top: 14px;
    padding-bottom: 14px;
}

.dropdown-menu {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-width: 2px;
}

.dropdown-shim-bolt {
    padding-left: 8px;
    padding-right: 11px;
}

.dropdown-shim-move {
    padding-left: 8px;
    padding-right: 6px;
}

.dropdown-shim-link {
    padding-left: 6px;
    padding-right: 6px;
}

.dropdown-inline {
    width: 300px;
}

.dropdown-regular > li > a {
    font-size: 17px !important;
}

.dropdown-small > li > a,
.dropdown-small > li {
    font-size: 15px !important;
}

.dropdown-menu {
    padding-top: 14px;
    padding-bottom: 14px;
}

.dropdown-menu-apps {
    min-width: 180px;
}

.dropdown-menu {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-width: 2px;
}

.dropdown-shim-move {
    padding-left: 8px;
    padding-right: 6px;
}

.dropdown-shim-link {
    padding-left: 6px;
    padding-right: 6px;
}

.dropdown-inline {
    width: 300px;
}
.dropdown-inline > li {
    float: left;
    width: 130px;
    padding: 0px 0px 0px 0px;
    margin: 0px;
}

/* NAVBAR ACCOUNT DROPDOWN */

.account-dropdown-container {
    padding-right: 20px;
    padding-left: 20px;
}

.account-dropdown-user {
    font-weight: 400;
    font-size: 20px;
}

.account-dropdown-progress {
    margin-top: 16px;
    margin-bottom: 14px;
}

.account-dropdown-quota {
    font-weight: 400;
    font-size: 14px;
    color: #777777;
}

.navrightdrop {
    width: 300px;
    text-align: right;
}

.navrightdrop-ul {
    width: 300px;
}

@media (max-width: 992px) {
    .navrightdrop {
        width: 200px;
    }
    .navrightdrop-ul {
        width: 200px;
    }
}

@media (max-width: 768px) {
    .navrightdrop {
        width: 285px;
        position: absolute !important;
        right:0;
    }
    .navrightdrop-ul {
        width: 300px;
    }
}

/*NAVBAR MISC */

.navbar-title {
    font-size: 17px;
    color: white;
    margin-top: 14px;
    margin-left: 56px;
    position: absolute;
    width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.navbar-subtitle {
    font-size: 14px;
    color: white;
    margin-top: 38px;
    margin-left: 56px;
    position: absolute;
}

.form-inline > .form-group {
    vertical-align: top;
}

/* TABLE CONTACINER BOX WITH FLOATING MENU RIGHT PADDING */

.header-title {
    padding: 26px 0px 20px 0px;
    font-size: 24px;
    width: 0;
    min-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 70px;
}

.header-title i {
    padding-left: 6px;
    padding-right: 6px;
}

.header-title a {
    color: #00adef;
}

.header-title a:hover {
    text-decoration: none;
    color: #0d3b66;
}

.header-box-withmenu {
    width: 100%;
    background-color: white;
    padding-right: 312px;
    z-index: 2;
}

.header-box-withmenu-link {
    /* width: 75%; */
    background-color: white;
    /* padding-right: 55px; */
    /* z-index: 2; */
}

@media (max-width: 992px) {
    .header-box-withmenu {
        padding-right: 16px;
    }
}

.content-box-withmenu {
    width: 100%;
    padding-right: 280px;
    margin-top: 107px;
}

.content-box-withmenu-link {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    /* margin-top: 107px; */
}

@media (max-width: 992px) {
    .content-box-withmenu {
        padding-right: 16px; 
    }

    .tab-wrapper .content-box-withmenu {
        margin-top: 150px;
    }

    .content-box-withmenu-link {
        /* margin-top: 197px; */
    }
}

.content-box-no-header {
    margin-top: 80px;
}

/* LIST BOX MENU RIGHT  */

.right-menu {
    right: 0;
    width: 230px;
    margin-right: 32px;
    margin-top: 106px;
}

.right-menu-link {
    right: 0;
    /* width: 100%; */
    /* margin-right: 16px; */
    margin-top: 106px;
    color: var(--linkTextColor) !important;
}

@media (max-width: 992px) {
    .right-menu-link {
        position: relative;
        margin-top: -0px;
    }
}
*/

/* .affix-menu-link {
    position: fixed;
    right: 0;
}

@media (max-width: 992px) {
    .affix-menu-link {
        position: relative;
        margin-top: -100px;
    }
} */

#path-item-menu {
    width: 230px;
}

#path-item-menu {
    width: 230px;
}
.right-menu * {
    /* color:#00adef!important; */
    color: var(--linkTextColor) !important;
}

.right-menu-dropdown-menu {
    position: relative;
    display: inline;
    /*top:18px;*/
    width: 100%;
}

.right-menu-dropdown-menu-link {
    position: relative;
    display: inline;
    /*top:18px;*/
    width: 100%;
    margin-bottom: 4px;
    margin-top: 4px;
}

.preview-menu {
    width: 230px;
    float: right;
    margin-top: 16px;
}

.preview-menu * {
    /* color:#00adef!important; */
}

.preview-container {
    /* margin-top: 90px; */
}

@media screen and (max-width: 768px) {
    .preview-container {
        margin-top: 30px;
    }
}

.preview-file-margin {
    margin-top: -20px;
    margin-bottom: 70px;
}
.preview-menu * {
    /* color:#00adef!important; */
}

.preview-wrap {
    overflow-y: auto;
    height: 100%;
    background-color: white;
    margin-right: 258px;
}

.preview-wrap-no-margin {
    overflow-y: auto;
    height: 100%;
    background-color: white;
}

.preview-wrap-no-overflow {
    overflow-y: visible;
}

@media (max-width: 992px) {
    .right-menu {
        bottom: -10px;
        width: 300px;
        margin-bottom: 36px;
        right: -16px;
    }

    .right-menu-dropdown-menu li {
        float: left !important;
    }

    .preview-menu {
        position: fixed;
        width: 300px;
        bottom: 0;
        margin-bottom: 36px;
        right: 16px;
        float: none;
    }

    .preview-menu li {
        float: left !important;
    }

    .preview-wrap {
        margin-right: 0px;
        /* padding-bottom: 80px; */
    }
}

.preview-wrap-icon {
    text-align: center;
    height: 100%;
    width: 100%;
    display: table;
    padding-left: 16px;
    padding-right: 16px;
}

.preview-wrap-icon-inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 100%;
    /*padding-bottom:80px; */
}

.preview-wrap-icon-inner .btn-primary {
    margin-top: 16px;
    margin-left: 4px;
    margin-right: 6px;
    background-color: #00adef;
    border-color: #00adef;
    width: 180px;
}

.preview-wrap-icon-inner .btn-primary:hover {
    background-color: #0d3b66;
    border-color: #0d3b66;
}

.preview-wrap-icon-inner-file-icon {
    width: 160px;
    height: 160px;
}

.preview-wrap-icon-inner-file-name {
    font-size: 17px;
}

.preview-wrap-img {
    margin: 0 auto;
    max-height: 75vh;
    padding-bottom: 60px;
}

.preview-image-nav {
    padding: 16px;
    font-size: 18px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    width: 282px;
    position: fixed;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 258px;
    margin-bottom: 16px;
}

.preview-image-nav-link {
    padding: 16px;
    font-size: 18px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    /* width: 282px; */
    width: 100%;
    /* position: fixed; */
    position: relative;
    display: inline;
    /* bottom: 0; */
    margin-left: auto;
    margin-right: auto;
    /* left: 0; */
    /* right: 258px; */
    margin-bottom: 4px;
    /* margin-top: 16px; */
}

@media (max-width: 992px) {
    .preview-image-nav-link {
        /* width: 380px; */
        right: 0;
    }

    .preview-image-nav {
        width: 380px;
        right: 0;
    }

    .dropdown-preview-image {
        display: none;
    }
}

.preview-image-right-arrow {
    margin-left: 150px;
}

.preview-image-nav-count {
    margin-top: -25px;
    position: absolute;
    width: 180px;
    text-align: center;
    z-index: -1;
}

.preview-iframe {
    display: block;
    width: 100%;
    height: calc(100vh - 70px);
    border-style: none;
    margin: 0px;
    padding: 0px;
}

.preview-iframe-wopi {
    display: block;
    width: 100%;
    height: calc(100vh - 70px);
    border-style: none;
    margin: 0px;
    padding: 0px;
}

@media (max-width: 992px) {
    .preview-iframe {
        height: calc(100vh - 160px);
    }

    .preview-iframe-wopi {
        height: calc(100vh - 160px);
    }
}

.right-menu-button {
    right: 0;
    top: 86px;
    margin-right: 32px;
    margin-top: 16px;
}

.right-menu-button-push {
    margin-top: 100px;
}

.right-menu-button-no-header {
    margin-top: 68px;
}

.right-menu-button .btn {
    width: 230px;
    font-size: 17px;
    background-color: #00adef;
    border-color: #00adef;
    border-width: 2px;
    color: white;
}

.btn-events-cal {
    height: 34px;
    font-size: 14px !important;
    width: 40px !important;
}

@media (max-width: 992px) {
    .right-menu-button {
        display: none;
    }
}

.right-menu-button .btn:hover {
    background-color: #0d3b66;
    border-color: #0d3b66;
    border-width: 2px;
}

.right-menu-button-info {
    margin-top: 10px;
    font-weight: 400;
    font-size: 15px;
    color: #707070;
}

.error-general-overlay {
    position: fixed;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 60%;
    height: auto;
    margin-top: 86px;
    z-index: 1049;
    border-radius: 4px;
    background-color: #444444;
    color: #ffffff;
    font-size: 17px;
    padding: 16px;
}

.error-general-overlay-login {
    position: relative;
    width: 100%;
    margin: 0px;
    margin-bottom: 24px;
}

.error-general-overlay-inline {
    position: relative;
    width: 100%;
    margin: 0px;
}

.error-general-overlay-message {
    width: 40%;
}

.error-general-overlay-message p {
    margin: 0;
}

.error-push {
    margin-top: 16px;
}

.error-push-bottom {
    margin-bottom: 16px;
}

@media (min-width: 992px) {
    .drop-section {
        min-height: 485px;
    }
}

/* TABLES */

.list-table {
    font-size: 17px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: #ddd;
    background-color: white;
}

.list-table-header {
    width: 100%;
    background-color: white;
    margin-bottom: 0px;
}

th {
    font-size: 14px;
    color: #707070;
    font-weight: normal;
}

td {
    font-weight: 400;
    vertical-align: middle !important;
}

.list-table > tbody > tr > td {
    padding-top: 16px;
    padding-bottom: 16px;
}

.list-table-modal > tbody > tr > td {
    padding-top: 8px;
    padding-bottom: 8px;
}

tbody > tr:hover .table-filename {
    color: #00adef !important;
}
tbody > tr:hover .table-actions {
    color: #00adef !important;
}
tbody:hover {
    cursor: pointer;
}

tbody.nopointer:hover {
    cursor: default;
}

.nopointer {
	cursor:default
}

.list-table-push {
    margin-top: 16px;
}

/* OVERFLOW FOR CELLS */

.tdmore {
    width: 0;
    min-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* FILES AND VAULT TABLE */

.table-checkbox {
    width: 40px;
    min-width: 40px !important;
    color: #a2a2a2;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 8px !important;
    padding-right: 0px !important;
    /*background-color:red;*/
}

.table-favorite{
    width: 60px;
    min-width: 60px;
    text-align: center;
}

.table-filename {
    width: 40%;
    /*background-color:orange;*/
}
@media (max-width: 992px) {
    .table-filename {
        width: 75%;
        /*background-color:blue;*/
    }
}
@media (min-width: 1200px) {
    .table-filename {
        width: 50%;
        /*background-color:black;*/
    }
}

.table-preview {
    /*background-color:yellow;*/
    width: 50px !important;
    min-width: 50px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.table-preview img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.table-date {
    min-width: 120px;
    width: 20%;
    /*background-color:green;*/
}

.table-date-link {
    min-width: 180px;
    width: 20%;
}

@media (max-width: 767px) {
    .table-date-link { 
        min-width: 128px;
    }
}

.table-size {
    width: 125px;
    min-width: 125px !important;
}
.table-shares {
    width: 68px;
    min-width: 68px;
}
.table-shares i {
    color: #a2a2a2;
    padding-right: 6px;
    padding-left: 3px;
}
.table-shares .btn {
    font-size: 17px;
    width: 86px;
    border-color: #00adef;
    border-width: 2px;
    background-color: #00adef;
    color: #ffffff;
}
.table-shares .btn:hover {
    background-color: #0d3b66;
    border-color: #0d3b66;
    color: #ffffff;
}

.table-actions {
    text-align: right;
    min-width: 50px;
    color: #707070;
}

.table-modalshareactions {
    text-align: right;
    min-width: 50px;
}

.table-info {
    font-size: 14px;
    color: #707070;
}

.actions-dropdown > ul > li * {
    color: #00adef !important;
}

.actions-global {
    padding-top: 16px;
    padding-bottom: 16px;
    max-width: 500px;
}

.actions-global .btn {
    border-color: #00adef;
    background-color: #00adef;
    color: #ffffff;
}

.actions-global .btn:hover {
    border-color: #0d3b66;
    background-color: #0d3b66;
    color: #ffffff;
}

.actions-global .input-group {
    z-index: 0;
}

.table-sharename {
    width: 40%;
}
@media (min-width: 1200px) {
    .table-sharename {
        width: 50%;
    }
}

@media (max-width: 992px) {
    .table-sharename {
        width: 75%;
    }
}

.table-shareadmin {
    width: 25%;
}

.table-shareusers {
    width: 25%;
}

.table-modalsharename {
    width: 40%;
}
.table-modalsharestatus-inactive {
    width: 50% !important;
}

.table-modalsharestatus {
    width: 30%;
}

.table-linkname {
    width: 50%;
}
@media (min-width: 1200px) {
    .table-linkname {
        width: 60%;
    }
}

.table-linkdate {
    width: 30%;
    min-width: 75px;
}

@media (max-width: 992px) {
    .table-linkname {
        width: 75%;
    }
}

.subtext {
    font-size: 14px;
    color: #a2a2a2;
}

.table-linkdownload {
    width: 10%;
    min-width: 100px;
}

@media (max-width: 992px) {
    .table-linkdownload {
        min-width: 60px;
    }
}

.table-username {
    width: 40%;
}
@media (max-width: 768px) {
    .table-username {
        width: 50%;
    }
}

.table-useractivity {
    min-width: 100px;
}

.table-userusage {
    width: 15%;
    min-width: 100px;
}

.table-usersettings {
    min-width: 50px;
    font-size: 14px;
    color: #a2a2a2;
}

.table-eventicon {
    width: 60px;
    vertical-align: top !important;
}

.table-eventname {
    width: 80%;
    vertical-align: top;
}

.table-eventdate {
    width: 20%;
    min-width: 180px;
    vertical-align: top !important;
}

.table-eventdetailsicon {
    width: 40px;
}

.table-eventdetailspreview {
    width: 40px;
    max-width: 40px;
}

.table-eventdetailspreview img {
    width: 30px;
    height: 30px;
}
.table-eventdetailsname {
    width: 70%;
}
.table-eventdetailssize {
    width: 20%;
}

.table-transferpreview {
    width: 40px;
    min-width: 40px;
    max-width: 40px;
}
.table-transfername {
    width: 60%;
}
.table-transfersize {
    width: 20%;
    min-width: 80px;
}
.table-transferstatus {
    text-align: right;
    color: #707070;
    min-width: 100px;
}
.table-transfermsg {
    min-width: 140px;
    color: #707070;
}
.table-transferprogress {
    border-style: none !important;
    padding: 0px !important;
    margin: 0px !important;
}

.table-transferstatus .btn-primary {
    background-color: #00adef;
    border-color: #00adef;
}

.table-transferstatus .btn-primary:hover {
    background-color: #0d3b66;
    border-color: #0d3b66;
}

.table-modalchoosercheck {
    width: 5%;
    max-width: 24px;
    color: #a2a2a2;
}
.table-modalchoosericon {
    width: 5%;
    max-width: 28px;
}

.table-modalchoosericon img {
    padding-bottom: 3px;
}
.table-modalchoosername {
    width: 85%;
}
.table-modalchooseroptions {
    width: 5%;
    max-width: 24px;
}

/* SYNC TOOL TIP */

.sync-tool-tip {
    background-color: #444444;
    color: #ffffff;
    border-radius: 4px;
    margin-top: 8px;
    padding: 8px;
    position: absolute;
    max-width: 300px;
    z-index: 1;
    font-size: 14px;
}

.sync-tool-tip:before {
    content: ;
    position: absolute;
    border-style: solid;
    border-width: 8px 8px;
    border-color: transparent transparent #444444 transparent;
    left: 16px;
    top: -16px;
} /*TOP ARROW */

.file-empty {
    display: table;
    width: 100%;
    margin-top: 16px;
}
.file-empty-inner {
    width: 100%;
    vertical-align: middle;
    text-align: center;
    display: table-cell;
    height: calc(100vh - 236px);
    border-style: solid;
    border-width: 0px 0px 2px 0px;
    border-color: #dddddd;
    font-size: 17px;
    padding-bottom: 42px;
}
.file-empty-text {
    max-width: 400px;
    margin: auto;
}

.file-loading {
    margin: auto;
    max-width: 400px;
    height: 22px;
}

.linkshare-empty {
    padding-top: 56px !important;
    padding-bottom: 64px !important;
}
/* GRID VIEW */
/* BETA NEED TO REVISIT THESE */

.grid-square-container {
    padding-top: 16px;
    text-align: center;
}

.grid-square {
    width: 100%;
    max-width: 200px;
    height: 200px;
    line-height: 200px;
    display: table;
}
.grid-square-image {
    display: table-cell;
    vertical-align: middle;
}
.grid-square-image img {
    margin-left: auto;
    margin-right: auto;
    max-height: 200px;
}

@media only screen and (max-width: 1200px) {
    .grid-square {
        max-width: 200px;
        height: 200px;
        line-height: 200px;
    }
    .grid-square-image img {
        max-height: 200px;
    }
}

@media only screen and (max-width: 992px) {
    .grid-square {
        max-width: 175px;
        height: 175px;
        line-height: 175px;
    }
    .grid-square-image img {
        max-height: 175px;
    }
}

@media only screen and (max-width: 768px) {
    .grid-square {
        max-width: 200px;
        height: 200px;
        line-height: 200px;
    }
    .grid-square-image img {
        max-height: 200px;
    }
}

@media only screen and (max-width: 480px) {
    .grid-square {
        max-width: 150px;
        height: 150px;
        line-height: 150px;
    }
    .grid-square-image img {
        max-height: 150px;
    }
}

.grid-container {
    border-bottom-color: #dddddd;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
}

.grid-container .grid-square-container .file-list-menu-dropdown .dropdown-menu {
    top: -13px;
    left: 10px;
}

.grid-container .grid-square-container .file-list-menu-dropdown li i{
    padding: 0px;
    width: 25px;
    text-align: left;
    margin-left: 7px;
}

.grid-square-filename {
    text-align: center;
    font-size: 15px;
    line-height: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 16px;
    height: 46px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.grid-overlay-box {
    border-style: solid;
    border-width: 1px;
    border-color: #c2c2c2;
    border-radius: 4px;
    background-color: white;
    width: 34px;
    font-size: 17px;
    color: #707070;
    position: absolute;
}

.grid-overlay-box i {
    padding: 6px 8px 6px 8px;
}

.grid-overlay-box-right {
    left: 0;
    margin-left: 48px;
}

.grid-overlay-box-left {
    left: 0;
    margin-left: 8px;
}

.grid-sync-icon {
    max-width: 136px;
}

.link-upsell {
    position: fixed;
    bottom: 0;
    padding-bottom: 8px;
    width: 100%;
    padding-right: 64px;
    /*background-color:white;*/
}

/* ACCOUNT */

.content-account {
    font-size: 16px;
}

.content-account-summary {
    padding-top: 32px;
    padding-bottom: 16px;
}

.content-account-sync-logo {
    float: left;
    margin-right: 16px;
    width: 46px;
    height: 46px;
}

.content-account-summary-details {
    display: table-cell;
    width: 400px;
}

.content-account-planname {
    font-size: 20px;
}

.content-account-summary-buttons > .btn-primary {
    background-color: #00adef;
    border-color: #00adef;
    font-size: 17px;
}

.content-account-summary-buttons > .btn-primary:hover {
    background-color: #0d3b66 !important;
    border-color: #0d3b66;
    font-size: 17px;
}

.content-account-summary-buttons > .btn-default {
    font-size: 17px;
}

.account-progress {
    height: 22px;
    margin-top: 16px;
    margin-bottom: 14px;
}

.btn-rewards {
    background-color: #00adef !important;
    border-color: #00adef !important;
}

.btn-rewards:hover {
    background-color: #0d3b66 !important;
    border-color: #0d3b66 !important;
}

.earned-it {
    padding-top: 8px;
}

.subtextfull {
    color: #a2a2a2;
}

/*LOGIN */

.navbar-white {
    background-color: white;
}

.navbar-white-header {
    max-width: 300px;
}

.navbar-white-q {
    float: left;
}

.navbar-white-right > li > a:hover {
    color: #00adef !important;
}

.navbar-white-preview > .navbar-title,
.navbar-white-preview > .navbar-subtitle,
.navbar-white-header > .navbar-brand,
.navbar-white-right > li > a {
    color: #0d3b66 !important;
}
.navbar-single-view > .navbar-white-preview > .navbar-title,
.navbar-single-view > .navbar-white-preview > .navbar-subtitle {
    margin-left: 70px;
}

.outer {
    display: table;
    height: 100vh;
    width: 100%;
}

.middle {
    display: table-cell;
    vertical-align: middle;
}

.container-background {
    background-color: #00adef;
    height: 100%;
    min-height: 100vh;
    background-image: url("https://www.sync.com/images/home-background.png");
    background-size: 524px 243px;
}

.bgwhite {
    background-color: white;
}

.login-form-container {
    padding-top: 150px;
    padding-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    font-size: 17px;
}

.login-header-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
    line-height: 32px;
}

.login-form-control {
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
}

.login-progress {
    height: 46px;
    margin: 0px;
    margin-top: 32px;
    padding: 0px;
    background-color: #00adef;
}

.btn-login {
    background-color: transparent;
    border-color: #00adef;
    margin-top: -46px;
}

.btn-login:hover {
    background-color: #0080a8;
    border-color: #0080a8;
}

.login-link {
    margin-top: 32px;
}

.btn-login-other {
    background-color: #00adef;
    border-color: #00adef;
}

.btn-login-other:hover {
    background-color: #0d3b66;
    border-color: #0d3b66;
}

.btn-oauth {
    /* max-width: 300px;
    min-width: 200px;
    vertical-align: middle; */
    margin-left: 20%;
    margin-right: 20%;
    width: 60%;
}

.showhand,
.hand {
    cursor: pointer;
    cursor: hand;
}

.hidden-file-upload {
    bottom: 0;
    cursor: pointer !important;
    width: 36px !important;
    overflow: hidden;
    margin: 0;
    height: 30px !important;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    left: -3000px;
}

.panel-push {
    margin-top: 88px;
}

.panel-title {
    font-size: 20px;
}

.panel-body {
    font-size: 16px;
}

.panel-footer > .btn {
    font-size: 17px;
    background-color: white;
}

.panel-footer > .btn-primary {
    background-color: #00adef !important;
    border-color: #00adef;
}

.panel-footer > .btn-primary:hover {
    background-color: #0d3b66 !important;
    border-color: #0d3b66;
}

.content-account-push {
    margin-top: 132px;
}

.copyright-notice {
    padding: 0px 32px 8px 0px;
    position: fixed;
    text-align: right;
    bottom: 0;
    right: 0;
}

@media (max-width: 992px) {
    .copyright-notice {
        padding: 0px 32px 2px 0px;
    }
}

.getspace-title {
    font-size: 26px;
    text-align: center;
    padding-bottom: 16px;
}

.getspace-features {
    font-size: 16px;
    height: 325px;
}
.getspace-features strong {
    font-weight: 900;
}
.getspace-product-family {
    border: 1px solid #e9ecef;
    padding: 16px;
}

.preview-wrap-wopi-fullscreen {
    margin: 0;
    padding: 0;
    overflow: hidden;
    -ms-content-zooming: none;
}

.preview-iframe-wopi {
    height: 100%;
    width: 100%;

    border: none;
    display: block;
}

.preview-iframe-wopi-fullscreen {
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    border: none;
    display: block;
    position: absolute;
}

.billing-invoice-unpaid {
    font-weight: bold;
    color: #f00;
}

.ellipsis-text {
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-item-name {
    display: table-cell;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .event-item-name {
        display: table-cell;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
        white-space: nowrap;
    }
}

@media (max-width: 992px) {
    .event-item-name {
        display: table-cell;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
        white-space: nowrap;
    }
}

/* CUSTOM WHITE LABEL */

:root {
    --headerPrimaryColor: #00adef;
    --headerTextColor: white;
    --buttonPrimaryColor: #00adef;
    --buttonTextColor: white;
    --linkTextColor: #00adef;
}

.syncblue-custom {
    color: var(--linkTextColor) !important;
}

.navbar-white-custom {
    background-color: var(--headerPrimaryColor);
    border-color: var(--headerPrimaryColor);
}

.navbar-table-custom {
    margin-top: 14px;
    margin-left: 10px;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    table-layout: fixed;
    max-width: 50%;
    z-index: 1;
}

.navbar-table-custom th {
    font-size: 17px;
    color: var(--headerTextColor);
}

.navbar-table-custom td {
    font-size: 14px;
    color: var(--headerTextColor);
}

.navbar-white-preview-custom > .navbar-title,
.navbar-white-preview-custom > .navbar-subtitle,
.navbar-white-header-custom > .navbar-brand,
.navbar-white-right-custom > li > a {
    color: var(--headerTextColor) !important;
}
.navbar-white-right-custom > li > a:hover {
    background-color: var(--headerPrimaryColor);
}

.right-menu-custom * {
    color: var(--linkTextColor) !important;
}

.logo-custom,
.logo-dialog-custom {
    max-width: 175px;
    max-height: 40px;
    width: auto;
    height: auto;
    vertical-align: text-bottom;
}

/* ----------------------- COMMENT STYLES ---------------------- */

.comment-notifier {
    z-index: 5000;
    color: white;
    background-color: #00bd47;
    border-radius: 8px;
    padding: 8px;
    font-size: 16px;
    width: 400px;
    position: fixed;
    left: 50%;
    margin-left: -200px;
    margin-top: 10px;
    top: 0px;
}

.text-only-btn {
    border: none;
    background-color: white;
    padding: 0;
}

.anonymous-form-buttons .btn-primary,
.anonymous-name-prompt .btn-primary {
    background-color: #00adef !important;
    border-color: #00adef !important;
    color: white !important;
}

.anonymous-form-buttons .btn-primary:hover,
.anonymous-name-prompt .btn-primary:hover {
    background-color: #0d3b66 !important;
    border-color: #0d3b66 !important;
    color: white !important;
}

.anonymous-form-buttons .btn-primary-custom,
.anonymous-name-promt .btn-primary-custom {
    background-color: var(--buttonPrimaryColor) !important;
    border-color: var(--buttonPrimaryColor) !important;
    color: var(--buttonTextColor) !important;
}

.anonymous-name-promt .btn {
    padding: 5px 12px;
}

.anonymous-name-promt .anonymous-name-text-box {
    height: 30px;
    vertical-align: middle;
    border-radius: 4px;
    border-width: 1px;
}

.anonymous-input-row {
    margin-top: 8px;
}

.comment-reply-input-buttons > .btn-primary,
.comment-text-input-box .btn-primary {
    background-color: #00adef !important;
    border-color: #00adef !important;
    color: white !important;
}

.comment-reply-input-buttons > .btn-primary:hover,
.comment-text-input-box .btn-primary:hover {
    background-color: #0d3b66 !important;
    border-color: #0d3b66 !important;
    color: white !important;
}

.comment-reply-input-buttons > .btn-primary-custom,
.comment-text-input-box .btn-primary-custom {
    background-color: var(--buttonPrimaryColor) !important;
    border-color: var(--buttonPrimaryColor) !important;
    color: var(--buttonTextColor) !important;
}

.reply-signin-options .link-primary,
.comment-signin-options .link-primary,
.comment-outer-box-buttons > .link-primary,
.comment-text-box-details .link-primary {
    color: #00adef !important;
}

.reply-signin-options .link-primary:hover,
.comment-signin-options .link-primary:hover,
.comment-outer-box-buttons > .link-primary:hover,
.comment-text-box-details .link-primary:hover {
    color: #0d3b66 !important;
}

.reply-signin-options .link-primary-custom,
.comment-signin-options .link-primary-custom,
.comment-outer-box-buttons > .link-primary-custom,
.comment-text-box-details .link-primary-custom {
    color: var(--linkTextColor) !important;
}

.comment-outer-box-buttons > .link-primary-custom:hover,
.comment-text-box-details .link-primary-custom:hover {
    color: var(--linkTextColor) !important;
}

.comment-box {
    padding-left: 16px;
    padding-right: 16px;
    /* margin-top: 16px; */
    cursor: default;
    margin-bottom: 100px;
}

.comment-header {
    font-size: 17px !important;
    color: rgba(0, 0, 0, 0.5);
}

.comment-text-input-box {
    margin: 8px 0px 8px 0px;
}

.comment-text-input {
    margin: 0px 0px 5px 0px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 40px;
    font-size: 14px;
    padding: 0px 0px 8px 8px;
    box-shadow: 0;
    background-color: #fff;
}

.comment-text-input-edit {
    margin: 0px 0px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    width: 100%;
    font-size: 14px;
    padding: 0px 0px 8px 8px;
    box-shadow: 0;
    background-color: #fff;
    height: auto;
}

.comment-text-area {
    border: none;
    margin: 0px 0px 0px 0px;
    width: 100%;
    font-size: 14px;
    padding: 0px 0px 8px 8px;
    background-color: #fff;
    height: auto;
}

.comment-text-display {
    display: inline-block;
    white-space: pre-line;
    word-break: break-word;
    cursor: default;
}

.comment-outer-box {
    border: 1px solid rgba(0, 0, 0, 0.25);
    font-size: 14px;
}

.comment-outer-box-buttons {
    padding: 4px 8px 4px 8px;
}

.comment-outer-box-button-bar {
    font-size: 12px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-width: 1px 0px 0px 0px;
}

.comment-top-level-box {
    padding: 8px 8px 0px 8px;
    cursor: default;
}

.comment-top-level-box-table {
    width: 100%;
}

.comment-top-level-avatar-box-with-line {
    vertical-align: top !important;
    background-image: url("../../images/line-v.png");
    background-repeat: repeat-y;
    background-position: center;
    background-size: 2px 2px;
    width: 1%;
}

.comment-top-level-avatar-box-no-line {
    vertical-align: top !important;
    width: 1%;
}

.comment-avatar {
    width: 30px;
    height: 30px;
    cursor: default;
    object-fit: cover;
}

.comment-avatar-spacer {
    width: 20px;
    height: 8px;
    background-color: #ffffff;
}

.comment-text-box {
    padding-top: 6px;
    padding-left: 8px;
    padding-bottom: 8px;
    vertical-align: top !important;
    cursor: default;
}

.comment-text-box-details {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.5);
}

.comment-text-box-content-length {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: -5px;
}

.comment-reply-marker {
    vertical-align: top !important;
    width: 30px;
    padding-left: 9px;
    background-image: url("../../images/line-v.png");
    background-repeat: repeat-y;
    background-position: 22px;
    background-size: 2px 2px;
    cursor: default;
}

.comment-replies-table {
    margin-right: 5px;
    width: 100%;
}

.comment-replies-table tr:last-child .comment-reply-marker {
    background-image: url("../../images/line-v.png");
    background-repeat: no-repeat;
    background-position: 22px 0px;
    background-size: 2px 20px;
}

.comment-reply-marker-hline {
    margin-top: 15px;
    margin-left: 15px;
    width: 15px;
    height: 2px;
}

.comment-reply-avatar-box {
    padding-top: 4px;
    padding-left: 8px;
    vertical-align: top !important;
}

.comment-reply-text-box {
    padding-left: 8px;
    padding-top: 10px;
    padding-bottom: 8px;
    vertical-align: top !important;
    padding-right: 8px;
    width: 100%;
    cursor: default;
}

.comment-reply-text-input-box {
    margin: 8px 8px 8px 8px;
}

/* -------------------- STYLES FOR ANONYMOUS USER PROMPT -------------------- */

.comment-signin-options {
    margin: 0px 0px 16px 0px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    width: 100%;
    font-size: 16px;
    padding: 8px;
}

.reply-signin-options {
    width: 100%;
    font-size: 16px;
    padding: 8px;
}

.comment-signin-options-close {
    font-size: 12px;
    margin-top: 2px;
}

.comment-signin-options-options {
    padding-bottom: 8px;
}

.comment-anonymous-form-input {
    padding: 8px;
    width: 100%;
    margin-bottom: 8px;
}

/* ------------------------ STYLES FOR HEADER AVATAR ------------------------ */

.navbar-avatar-header-bar {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    float: left;
    margin-top: 16px;
    object-fit: cover;
}

.navbar-avatar-bigbox {
    width: 240px;
}

.navbar-avatar-smalltext {
    font-size: 12px;
    padding-left: 48px;
    margin-top: -10px;
    position: absolute;
    float: left;
    height: 70px;
    width: 240px;
}

.navbar-avatar-bigtext {
    margin-top: 8px;
    padding-left: 48px;
    position: absolute;
    float: left;
    height: 70px;
    width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.display-avatar-setting {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

/* ---------------------------- STYLES FOR IFRAME --------------------------- */
.comment-cp-iframe {
    width: 0;
    height: 0;
    position: absolute;
    border: 0;
}

.response-alert {
    margin-top: 20px;
    position: absolute;
    top: 0;
    right: 50px;
}

.theme-btn {
    background-color: #00adef;
    border-color: #00adef;
    margin-right: 8px;
}

.theme-btn:hover {
    background-color: #0d3b66;
    border-color: #0d3b66;
}

select.form-control {
    cursor: pointer;
}

a.disableMe {
    pointer-events: none;
    cursor: default;
}
.modal-body-svg {
    height: 50px;
    margin-left: 10px;
    margin-top: -5px;
}

.modal-body-div {
    margin-bottom: 15px;
}

.modal-body-div:last-child {
    margin-bottom: 0px;
}

.sync-typeahead + .dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1.5rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
}
.sync-typeahead + .dropdown-menu .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
.sync-typeahead + .dropdown-menu .dropdown-item:hover,
.sync-typeahead + .dropdown-menu .dropdown-item:active,
.sync-typeahead + .dropdown-menu  .dropdown-item.active{    
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd;    
}
/* ngbDatepicker */
.sync-event-calander.right-menu-button .ngb-dp-arrow .btn {
    font-size: inherit;
    background: inherit;
    color: #00adef;
}

.sync-event-calander.right-menu-button .ngb-dp-month .bg-light {
    background-color: #efefef;
}

.sync-event-calander.right-menu-button .ngb-dp-month .bg-light .small {
    color: #17a2b8;
}

.sync-event-calander.right-menu-button .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0 0.5rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
    color: #495057;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    appearance: none;
}

.actions-dropdown-right sync-file-list-menu{
    width: 230px;
    left: auto !important;
    right: 0 !important;
}

@media only screen and (min-width: 767.92px) and (max-width: 1200px) {
    .grid-container .grid-square-container .file-list-menu-dropdown .dropdown-menu {
        top: -13px;
        left: 10px;
    }
    .grid-container .grid-square-container:nth-child(4n) .file-list-menu-dropdown{
        position: absolute;
        left:auto !important;
        right:0;
    }
    .grid-container .grid-square-container:nth-child(4n) .file-list-menu-dropdown .dropdown-menu {
        left: auto;
        right: 10px;
        top: -13px;
    }
    .grid-container .grid-square-container .file-list-menu-dropdown li i{
        padding: 0px;
        width: 25px;
        text-align: left;
        margin-left: 7px;
    }

}

@media only screen and (max-width: 767.91px) {
    .grid-container .grid-square-container .file-list-menu-dropdown .dropdown-menu {
        top: -13px;
        left: 10px;
    }
    .grid-container .grid-square-container:nth-child(3n+3) .file-list-menu-dropdown{
        position: absolute;
        left:auto !important;
        right:0;
    }
    .grid-container .grid-square-container:nth-child(3n+3) .file-list-menu-dropdown .dropdown-menu {
        left: auto;
        right: 10px;
        top: -13px;
    }
    .grid-container .grid-square-container .file-list-menu-dropdown li i{
        padding: 0px;
        width: 25px;
        text-align: left;
        margin-left: 7px;
    }
}

.error-msg a {
    color: var(--linkTextColor) !important;
}

/* ngbPopover */
.table-transferstatus .popover {
    margin-top: -10px;
    border: 1px solid rgba(0, 0, 0, .2) !important;
}

.table-transferstatus .popover>.arrow {
    bottom: -20px;
    left: 50%;
    margin-left: -11px;
    border-top-color: rgb(255 255 255);
    border-bottom-width: 0;
    border-width: 11px;
}

.table-transferstatus .popover>.arrow:after {
    border-width: 10px;
    bottom: 1px;
    margin-left: -10px;
    content: " ";
    border-top-color: #fff;
    border-bottom-width: 0;
}

.table-transferstatus .popover-body {
    width: 200px;
    padding: 9px 14px;
}

.filelink {
    color: #444444;
    text-decoration: none;
}

.filelink:hover {
    text-decoration: none;
    color: var(--linkTextColor);
}

.table-recents-icon {
    width: 2%;
    vertical-align: top !important;
}

.table-recent-filename {    
    width: 40%;
}

.table-recent-date {
    min-width: 120px;
    width: 15%;    
}

.table-recent-shareadmin {    
    width: 15%;    
}

.table-recent-size {    
    width: 10%;    
}

.table-recent-location {        
    width: 18%;    
}

.recents-loader {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
}

.general-error-display {
    position: fixed;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 60%;
    height: auto;
    margin-top: 86px;
    z-index: 1049;
    border-radius: 4px;
    font-size: 17px;
    padding: 16px;
}

.disabled-element {
    pointer-events: none; 
    opacity: 0.5; 
}

.view-later-btn {
    margin-left: 5px
}